
import { License, UserInvite } from '@/models';
import { onMounted, defineComponent, ref, reactive } from 'vue';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import Message from 'primevue/message';
import { ApiService } from '@/services';
import { useToast } from 'primevue/usetoast';

export default defineComponent({
	components: {
		Button,
		InputText,
		Dropdown,
		Calendar,
		Message
	},
	setup() {
		const defaultDate = new Date(new Date().toDateString());
		enum InvoiceMode {
			Off,
			Invitee,
			Custom
		}
		const details = reactive<UserInvite>({
			firstName: '',
			lastName: '',
			organization: '',
			recipientEmail: '',
			licenseId: null,
			dateExpired: defaultDate,
			invoiceEmail: '',
			sendInvoice: false
		});
		const selectedLicense = ref<License>(null);
		const licenses = ref<License[]>([]);
		const toast = useToast();

		let invoiceMode = ref(InvoiceMode.Off);

		const invoiceModeOptions = [
			{ label: 'No', value: InvoiceMode.Off },
			{ label: 'Yes, to recipient email address', value: InvoiceMode.Invitee },
			{ label: 'Yes, to different email address', value: InvoiceMode.Custom }
		];

		const updateLicense = (license: License) => {
			details.licenseId = license.id;
			const targetDate = new Date(defaultDate);
			targetDate.setDate(targetDate.getDate() + license.duration);
			details.dateExpired = targetDate;
		};

		const updateInvoicing = (mode: InvoiceMode) => {
			details.invoiceEmail = '';
			details.sendInvoice = mode !== InvoiceMode.Off;
		};

		const submit = async () => {
			try {
				if (invoiceMode.value === InvoiceMode.Invitee) {
					details.invoiceEmail = details.recipientEmail;
				}

				await ApiService.post(`/users/invite`, details);
				toast.add({
					severity: 'success',
					summary: 'Success',
					detail: 'Invitation sent.',
					life: 3000
				});
			} catch (e) {
				toast.add({
					severity: 'error',
					summary: 'Error Occurred',
					detail: 'Unable to send invitation. Try again later.',
					life: 3000
				});
			}
		};

		onMounted(async () => {
			licenses.value = await ApiService.get<License[]>('/licenses/all');
		});

		return {
			InvoiceMode,
			details,
			licenses,
			selectedLicense,
			defaultDate,
			invoiceMode,
			invoiceModeOptions,
			updateLicense,
			updateInvoicing,
			submit
		};
	}
});
